"use client";
import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  padding: 0 32px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
