'use client'

import { styled } from "styled-components";

export const h1 = "h1"
export const h2 = "h2"
export const h3 = "h3"
export const secondary = "secondary"
export const bold = "bold"
export const caption = "caption"


export const TypographyWrapper = styled.p`
  font-family: --default-font, sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;

  &.${h1} {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0;
    @media (min-width: 1024px) {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 0.64px;
    }
  }

  &.${h2} {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0;
    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &.${h3} {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;
    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &.${h3} {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.4px;
  }

  &.${bold} {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  &.${secondary} {
    color: var(--text-secondary);
  }
  &.${caption} {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
  }
`;
