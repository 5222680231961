"use client";
import React, { useEffect } from "react";
import Link from "next/link";
import Typography from "@/ui/components/typography";
import * as S from "./styled";

export function Main({ data, countries }: { data: any; countries: any }) {
  useEffect(() => {
    const { dataHH, dataBackOnly, dataERR, dataBackWithDb } =
      data || {};
    console.log("dataHH", dataHH);
    console.log("dataBackOnly", dataBackOnly);
    console.log("dataERR", dataERR);
    console.log("dataBackWithDb", dataBackWithDb);
  });

  return (
    <S.Container>
      <Typography h1>Gay Dating & Chat site</Typography>
      <Typography bold>Available countries:</Typography>
      {countries.map((country: any) => (
        <Link href={`${country.pathname}`}>{country.name}</Link>
      ))}
    </S.Container>
  );
}
