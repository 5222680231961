"use client";
import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 32px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .site-name {
    font-weight: 700;
    font-size: 24px;
  }

  .logo-text {
    display: flex;
    flex-direction: column;

    .logo-desc {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .logo {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    img {
      width: 64px;
      height: 64px;
    }
  }
`;

export const HeaderList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 32px;
`;

export const HeaderListItem = styled.li`
  font-weight: 700;
  margin: 0;
  padding: 0;
`;
