import { TypographyWrapper } from "./styled";
import type { ComposeProps } from "../../types/util";
import cn from "classnames";
import { constStrArray, extractProps } from "../../util/type";
import { DefaultProps } from "../../types/core";


export const classNames = constStrArray(
  'h1',
  'h2',
  'h3',
  'secondary',
  'bold',
  'caption',
);

export interface Props
  extends DefaultProps,
    Partial<ComposeProps<typeof classNames, boolean>> {}

export default function Typography(props: Props) {
  return (
    <TypographyWrapper
      as={props.as}
      className={cn(props.className, extractProps(props, ...classNames))}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </TypographyWrapper>
  );
}
